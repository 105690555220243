





















































import { Component, Vue } from 'vue-property-decorator'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { Loja, TipoDeTransacaoDeVenda, TipoNota, Venda } from '@/models'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import IconesDaToolbarPadroes from '@/components/layout/IconesDaToolbarPadroes.vue'
import BotaoDeDrawer from '@/components/ui/BotaoDeDrawer.vue'

@Component({
	components: {
		IconesDaToolbarPadroes,
		SeletorDeLojas,
		BotaoDeDrawer,
	},
})
export default class ToolbarDeEmissaoDeNota extends Vue  {
	get tipoOperacao(): TipoDeTransacaoDeVenda | undefined {
		return this.venda?.tipoDeTransacao
	}

	set tipoOperacao(tipo: TipoDeTransacaoDeVenda | undefined) {
		if (!this.venda) return
		if (!tipo) return

		const venda = this.venda

		venda.tipoDeTransacao = tipo

		this.venda = venda
	}

	set tipoNota(tipo: TipoNota) {
		if (!this.venda) return
		if (!tipo) return

		const venda = this.venda

		venda.tipoNota = tipo

		this.venda = venda
	}

	get tipoNota() {
		if (!this.venda) return 'Saída'

		return this.venda.tipoNota
	}

	get loja(): Loja | null {
		return VendaModule.lojaDaEntrada
	}

	set loja(loja: Loja | null) {
		VendaModule.setLojaDaEntrada(loja)
	}

	get venda(): Venda | null {
		return VendaModule.emissaoEntradaAtual
	}

	set venda(venda: Venda | null) {
		VendaModule.setEmissaoEntradaAtual(venda)
	}

	get itensTipoNota() {
		const itens = ['Entrada', 'Saída']

		return itens.filter(item => {
			if (
				this.tipoOperacao === 'Transferência' || 
				this.tipoOperacao === 'Consumo' ||
				this.tipoOperacao === 'Ativo'
			) return item === 'Saída'
			else return item
		})
	}

	get devolucaoBuscada() {
		return this.$route.query.devolucaoBuscada != null ? this.$route.query.devolucaoBuscada === 'true' : false
	}

	async iniciarNovaEntrada() {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { novaEntrada: 'true' },
			})
			.catch()
	}
}
